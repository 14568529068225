<style>
.get {
  background-image: url(https://files.xsdhy.com/public/index01.jpg);
  color: #CC6600;
  text-align: center;
  padding: 100px 0;
}

.get-title {
  font-size: 200%;
  padding: 20px;
  display: inline-block;
}

.detail {
  background: #fff;
  padding: 50px 0;
}

.detail-h2 {
  text-align: center;
  font-size: 150%;
  margin: 0 0;
}

.detail-p {
  color: #7f8c8d;
  text-align: center;
}

.detail-mb {
  margin-bottom: 30px;
}

.hope {
  background-image: url(https://files.xsdhy.com/public/index02.jpg);
  padding: 50px 0;
  color: #fff;
  text-align: left;
}

.hope-title {
  font-size: 140%;
}

.about {
  background: #fff;
  padding: 40px 0;
  color: #7f8c8d;
}

.about-color {
  color: #34495e;
}

.about-title {
  font-size: 180%;
  padding: 30px 0 50px 0;
  text-align: center;
}
</style>
<template>
  <div>
    <br>
    <div class="get">
      <h1 class="get-title">
        消逝的红叶<br>我就是我，是不一样的烟火
      </h1>
      <br>
      <a href="https://www.xsdhy.com/life">
        心若没有栖息的地方，到哪里都是在流浪。
      </a>
    </div>
    <div class="detail">
      <h2 class="detail-h2">
        我知道，我一直都有着自己的那一份心情。 在这里，安静的做自己
      </h2>
      <p class="detail-p">
        昔日，黄昏所闪耀的校园小道，如今却成了黑暗与寒风的秀场。<br>那伫立的路灯为何是那样的昏黄？你想展现这人间怎样的沧桑？为何又让独行的我倍感这份凄凉。
      </p>
    </div>
    <div class="hope">
      <h2 class="hope-title">
        旧梦沧桑
      </h2>
      <p>
        不舍得遗忘，终究是因为不舍得斩断曾经付出的时光
      </p>
    </div>
    <div class="about">
      <h2 class="about-title about-color">多少回忆成浮光掠影，却还是梦不醒一场曾经</h2>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  created() {
    document.title = "消逝的红叶-我就是我，是不一样的烟火";
  },
  methods: {}
}
</script>
